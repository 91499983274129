import React, {useState, useEffect} from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import Products from './Components/Products';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import {css} from "@emotion/css";


function App() {

  const[loading,setLoading] = useState(false);
  const override = css`
  display:block;
  border-color:red;
  margin-top:20%;
  `;

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  },[])

  return (
    <div className="App">

      {
        <>
        <Navbar/>
        <Header/>
        <Products/>
        <About/>
        <Contact/>
        <Footer/>
        </>
      }
      
    </div>
  );
}

export default App;

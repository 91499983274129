import React from 'react';
import Productbox from './Productbox';
import mundo from '../images/images2/mundo.png';
import encomiendas from '../images/images2/encomiendas.png';
import transporte from '../images/images2/transporte.png';

 
function Products() {
    return (
        <div id='products'>
            <h1>Servicios</h1>
            <p></p>
            <div className='a-container'>
                <Productbox image={mundo} title="Ubicaciones" />
                <Productbox image={encomiendas} title="Encomiendas" />
                <Productbox image={transporte} title="Transporte y Turismo" />
            </div>
        </div>
    )
}

export default Products;

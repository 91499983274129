import React from 'react';

function Contact() {
    return (
        <div id='contact'>
            <div className='contact-mision'>
                <div className='mision'>
                    <h1>Misión</h1>
                     <p className='parrafo'>Garantizar el servicio óptimo de transporte terrestre de pasajeros, soluciones logísticas de encomiendas, con un equipo humano competente y comprometido, con tecnología y responsabilidad ambiental, exaltando una excelencia y calidad en el servicio, con el objetivo de generar beneficios a nuestros usuarios y colaboradores.</p>
                </div>        
            <div/>
                <div className='contact-vision'>   
                    <div className='vision'>
                        <h1>Visión</h1>
                         <p className='parrafo'>Satisfacer las necesidades de los clientes brindando servicios de transporte de la mejor calidad a nuestros usuarios con opción de transporte público, de pasajeros y de envíos de encomiendas; como resultado de la implementación de altos estándares de calidad que respondan a la preservación del medio ambiente, programas de reposición que permitan contar con un parque automotor moderno y tecnológico, garantizando la máxima comodidad y bajos índices de accidentalidad.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;

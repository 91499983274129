import React from "react";
import { BsFacebook, BsWhatsapp, BsMailbox } from "react-icons/bs";

function Footer() {
  return (
    <div id='footer' className="main-footer">
        <script src="https://kit.fontawesome.com/be73c3e0cb.js" crossorigin="anonymous"></script>
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <h4>LAGO YPOA S.A.</h4>
            <h4 className="list-unstyled">
              <li>Telefono: (021) 202335</li>
              <li>Dirección: Villa Elisa, Central</li>
              <li>Mandyju Pekua</li>
            </h4>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4></h4>
            <ul className="list-contact">
              <a className="BT-footer" href="mailto:lagoypoasa@gmail.com">
              <button className="list-button"><BsMailbox/> Correo</button>
              </a>
              <br/>
              <br/>
              <a className="BT-footer" href='https://wa.me/+595991230594'>
              <button className="list-button"><BsWhatsapp/> WhatsApp</button>
              </a>
              <br/>
              <br/>
              <a className="BT-footer" href='https://www.facebook.com/lagoypoa/?locale=es_LA'>
              <button className="list-button"><BsFacebook/> Facebook</button>
              </a>
            </ul>
          </div>
          {/* Column3 */}
        
        </div>
        <hr />
        <div className="row">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} Gracias por la preferencia - Desarrollado por Bruno Lird
          </p> 
        </div>
      </div>
    </div>
  );
}

export default Footer;
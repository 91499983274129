import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

function About() {
    return (
        <div id='about'>
            <div className='about-text'>
                <h1>Horarios</h1>
                <p>Los horarios podrán tener modificaciones según disponibilidad o inclemencias climáticas. Agradecemos su comprensión.</p>
                <a className='hourBt' href='https://www.plataforma10.com.py/'>
                <button className='hour-btn' >Comprar pasaje</button>
                </a>
                
            </div>
            <div>
                <table className='table table-bordered'>
                    <thead>
                        <tr >
                            <th colSpan={2}>Alberdi</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr>
                        <th>Asunción - Alberdi</th>
                        <th>Alberdi - Asunción</th>
                    </tr>
                    <tr>
                        <td>Lunes a Sabados</td>  
                        <td>Lunes a Sabados</td>                
                    </tr>
                    <tr>
                        <td>05:00 hs</td>
                        <td>03:00 hs</td>
                    </tr>
                    <tr>
                        <td>12:00 hs</td>
                        <td>11:00 hs</td>
                    </tr>
                    <tr>
                        <td>17:00 hs</td>
                        <td>16:30 hs</td>
                    </tr>
                    <tr>
                        <td>Domingos</td>  
                        <td>Domingos</td>  
                    </tr>
                    <tr>
                        <td>05:00 hs</td>
                        <td>03:00 hs</td>
                    </tr><tr>
                        <td>17:00 hs</td>
                        <td>16:30 hs</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div>
                <h1>|</h1>
            </div>
            <div>
                <table className='table table-bordered'>
                    <thead>
                        <tr >
                            <th colSpan={2}>Quiindy</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr>
                        <th>Asunción - Quiindy</th>
                        <th>Quiindy - Asunción</th>
                    </tr>
                    <tr>
                        <td>Lunes a Domingos</td>  
                        <td>Lunes a Domingos</td>                
                    </tr>
                    <tr>
                        <td>04:00 hs</td>
                        <td>02:00 hs</td>
                    </tr>
                    <tr>
                        <td>06:00 hs</td>
                        <td>03:00 hs</td>
                    </tr>
                    <tr>
                        <td>07:00 hs</td>
                        <td>04:00 hs</td>
                    </tr>
                    <tr>
                        <td>08:00 hs</td>
                        <td>05:00 hs</td>
                    </tr>
                    <tr>
                        <td>09:00 hs</td>
                        <td>06:00 hs</td>
                    </tr>
                    <tr>
                        <td>10:00 hs</td>
                        <td>08:00 hs</td>
                    </tr>
                    <tr>
                        <td>11:00 hs</td>
                        <td>09:00 hs</td>
                    </tr>
                    <tr>
                        <td>12:00 hs</td>
                        <td>10:00 hs</td>
                    </tr>
                    <tr>
                        <td>13:00 hs</td>
                        <td>11:00 hs</td>
                    </tr>
                    <tr>
                        <td>14:00 hs</td>
                        <td>12:00 hs</td>
                    </tr>
                    <tr>
                        <td>15:00 hs</td>
                        <td>13:00 hs</td>
                    </tr>
                    <tr>
                        <td>16:00 hs</td>
                        <td>14:00 hs</td>
                    </tr>
                    <tr>
                        <td>17:30 hs</td>
                        <td>15:00 hs</td>
                    </tr>
                    <tr>
                        <td>18:00 hs</td>
                        <td>15:30 hs</td>
                    </tr>
                    <tr>
                        <td>19:00 hs</td>
                        <td>17:00 hs</td>
                    </tr>
                </tbody>
                </table>
            </div>
                <h1>|</h1>
            <div>
                <table className='table table-bordered'>
                    <thead>
                        <tr >
                            <th colSpan={2}>Quyquyhó</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr>
                        <th>Asunción - Quyquyhó</th>
                        <th>Quyquyhó - Asunción</th>
                    </tr>
                    <tr>
                        <td>Lunes a Sabados</td>  
                        <td>Lunes a Sabados</td>                
                    </tr>
                    <tr>
                        <td>04:30 hs</td>
                        <td>04:00 hs</td>
                    </tr>
                    <tr>
                        <td>11:30 hs</td>
                        <td>05:00 hs</td>
                    </tr>
                    <tr>
                        <td>16:50 hs</td>
                        <td>06:00 hs</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>14:00 hs</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div>
                <h1>|</h1>
            </div>
            <div>
                <table className='table table-bordered'>
                    <thead>
                        <tr >
                            <th colSpan={2}>Mboi Ka'ê</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr>
                        <th>Asunción - Mboi Ka'ê</th>
                        <th>Mboi Ka'ê - Asunción</th>
                    </tr>
                    <tr>
                        <td>Lunes a Sabados</td>  
                        <td>Lunes a Sabados</td>                
                    </tr>
                    <tr>
                        <td>16:50 hs</td>
                        <td>05:00 hs</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div>
                <h1>|</h1>
            </div>
            <div>
                <table className='table table-bordered'>
                    <thead>
                        <tr >
                            <th colSpan={2}>María Antonia</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr>
                        <th>Asunción - María Antonia</th>
                        <th>María Antonia - Asunción</th>
                    </tr>
                    <tr>
                        <td>Lunes a Domingos</td>  
                        <td>Lunes a Domingos</td>                
                    </tr>
                    <tr>
                        <td>04:30 hs</td>
                        <td>02:00 hs</td>
                    </tr>
                    <tr>
                        <td>11:30 hs</td>
                        <td>12:00 hs</td>
                    </tr>
                </tbody>
                </table>
            </div>
            
        </div>
    )
}

export default About;

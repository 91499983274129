import React from 'react';

function Header() {
    return (
        <div id='main'>
            <div className='header-heading'>
                <h1>Bienvenido a Transporte Lago Ypoa</h1>
                <p className='details'>Empresa dedicada a brindar el mejor servicio a pasajeros y encomiendas con amplia cobertura geográfica.</p>
                <div className='header-btns'>
                    <a href='https://www.plataforma10.com.py/' className='header-btn'>Comprar Pasaje</a>
                </div>
            </div>
        </div>
    )
}

export default Header;
